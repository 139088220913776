import { createSlice } from '@reduxjs/toolkit';
import { CommunityDiscussionType, PaginationPropsType } from 'gazprom-common-lib';

export const SLICE_NAME = 'communityDiscussionsSlice';

export type communityDiscussionsSliceSliceState = PaginationPropsType & {
  communityDiscussionsToShow: CommunityDiscussionType[];
};

const DEFAULT_PAGINATION = { page: 1, size: 10 };

const initialState: communityDiscussionsSliceSliceState = {
  communityDiscussionsToShow: [],
  ...DEFAULT_PAGINATION,
};

export const communityDiscussionsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetPaginationTagsAndCommunityDiscussions(state) {
      state.page = DEFAULT_PAGINATION.page;
      state.size = DEFAULT_PAGINATION.size;
      state.communityDiscussionsToShow = [];
    },
    addCommunityDiscussions(state, action) {
      state.communityDiscussionsToShow = state.communityDiscussionsToShow.concat(action.payload);
    },
    addCommunityDiscussionToStart(state, action) {
      state.communityDiscussionsToShow.unshift(action.payload);
    },
    resetCommunityDiscussions(state) {
      state.communityDiscussionsToShow = [];
    },
    resetPagination(state) {
      state.page = DEFAULT_PAGINATION.page;
      state.size = DEFAULT_PAGINATION.size;
    },
    addPage(state) {
      state.page = state.page + 1;
    },
  },
});

export const {
  addPage,
  addCommunityDiscussions,
  resetPaginationTagsAndCommunityDiscussions,
  addCommunityDiscussionToStart,
  resetCommunityDiscussions,
  resetPagination,
} = communityDiscussionsSlice.actions;

export default communityDiscussionsSlice.reducer;
