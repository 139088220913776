import cn from 'classnames';

import { Badge, Icon, TIconsLiteral } from 'gazprom-ui-lib';

import s from './HeaderRightIcon.module.scss';

interface Props {
  counter?: number;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconName: TIconsLiteral;
}

const HeaderRightIcon = (props: Props) => {
  const { iconName, className, onClick, counter } = props;

  return (
    <button onClick={onClick} className={cn(s.wrapper, className)}>
      <Badge count={counter} size="small" offset={[-4, 4]}>
        <Icon name={iconName} />
      </Badge>
    </button>
  );
};

export default HeaderRightIcon;
