export const HOME_ROUTE = '/home';
export const HOME_SWIPER_ROUTE = `${HOME_ROUTE}/swiper`;
export const HOME_SELECT_COMPANY = `${HOME_ROUTE}/select-company`;

export const HOME_SALARY_PROJECT = `${HOME_ROUTE}/salary-project`;
export const HOME_SALARY_PROJECT_DETAILS = `${HOME_SALARY_PROJECT}/:id`;
export const getHomeSalaryProjectsDetailsRoute = (id: string) => `${HOME_SALARY_PROJECT}/${id}`;

export const HOME_NEWS_ROUTE = `${HOME_ROUTE}/news`;
export const HOME_NEWS_DETAILS_ROUTE = `${HOME_NEWS_ROUTE}/:newsCode`;
export const getNewsWithIdRoute = (newsCode: string) => `${HOME_NEWS_ROUTE}/${newsCode}`;

export const HOME_NEWS_TAGS_ROUTE = `${HOME_NEWS_ROUTE}/tags`;

export const HOME_PROMOCODES_ROUTE = `${HOME_ROUTE}/promocodes`;

export const HOME_PROMOCODES_DETAILS_ROUTE = `${HOME_PROMOCODES_ROUTE}/:id`;

export const getPromocodeWithIdRoute = (id: string) => `${HOME_PROMOCODES_ROUTE}/${id}`;

export const HOME_PROMOCODES_ORDER_BY_POST = `${HOME_PROMOCODES_ROUTE}/order-by-post`;

export const HOME_PROMOCODES_ORDER_PICKUP = `${HOME_PROMOCODES_ROUTE}/pickup`;
