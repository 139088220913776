import { ReactNode, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { Button, Flex, Icon, Typography } from 'gazprom-ui-lib';

import WithGzpTopLogo from 'containers/wrappers/with-gzp-top-logo';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { ReactComponent as PhoneIcon } from 'assets/icons/phoneIcon.svg';
import { ReactComponent as SupportIcon } from 'assets/icons/supportIcon.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegramIcon.svg';

import s from './Error.module.scss';
import useErrorConfig from './useErrorConfig.hook';

interface Props {
  initialActions?: ReactNode;
}

const Error = (props: Props) => {
  const { initialActions } = props;

  const {
    icon,
    title,
    text,
    actions: configActions,
    showSupport,
    iconClassName,
  } = useErrorConfig();

  const actions = initialActions || configActions;

  const [isSupportOpened, setIsSupportOpened] = useState<boolean>(false);

  const supportModalRef = useRef<HTMLDivElement>(null);

  const handleOpenCloseSupportModal = () => {
    setIsSupportOpened(!isSupportOpened);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (supportModalRef.current && !supportModalRef.current.contains(event.target as Node)) {
        setIsSupportOpened(false);
      }
    };

    if (isSupportOpened) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSupportOpened]);

  return (
    <WithStatusBar>
      <WithGzpTopLogo className={cn(s.wrapper, { [s.withoutSupport]: !showSupport })}>
        <>
          {showSupport && (
            <div className={s.supportWrapper} onClick={handleOpenCloseSupportModal}>
              <div className={s.support}>
                <div>
                  <Typography.Text type="secondary">
                    {t('error_tech_support_subtitle')}
                  </Typography.Text>
                  <Typography.Title level={4}>{t('error_tech_support_title')}</Typography.Title>
                </div>
                <div className={s.supportIconWrapper}>
                  <Icon name="phoneFilled" />
                </div>
              </div>
            </div>
          )}
          <div>
            <div className={cn(s.iconWrapper, s[iconClassName ?? ''])}>
              <Icon name={icon} />
            </div>
            <Typography.Title level={3}>{title}</Typography.Title>
            <Typography.Title level={5} type="secondary" strong={false}>
              {text}
            </Typography.Title>
          </div>
          <div className={s.actionsWrapper}>{actions}</div>
        </>

        {isSupportOpened && (
          <div className={s.supportModalWrapper}>
            <div ref={supportModalRef} className={s.supportModal}>
              <div onClick={handleOpenCloseSupportModal}>
                <Icon
                  size={20}
                  name="delete"
                  color="var(--color-primary-6)"
                  className={s.modalCloseIcon}
                />
              </div>
              <SupportIcon />
              <Flex vertical align="center" gap="4">
                <Typography.Title level={4}>{t('error_tech_support_title')}</Typography.Title>
                <Typography.Text
                  size="14"
                  type="secondary"
                  className="technicalSupportModalSubtitle supportModal">
                  {t('common_inner_number')}: <strong>9000</strong>
                </Typography.Text>
              </Flex>
              <Flex vertical gap="16" className={s.supportModalActions}>
                <Button fullWidth size="large" href="tel:+78126650535">
                  <PhoneIcon /> +7 812 665 05 35
                </Button>
                <Button
                  type="secondary"
                  fullWidth
                  size="large"
                  href="https://t.me/hotline_5555"
                  target="_blank">
                  <TelegramIcon /> {t('error_tech_support_contact_telegram')}
                </Button>
              </Flex>
            </div>
          </div>
        )}
      </WithGzpTopLogo>
    </WithStatusBar>
  );
};

export default Error;
