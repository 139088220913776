import { useAuth } from 'react-oidc-context';
import { t } from 'tools/i18n';
import { userManager } from 'utils/keycloak';

import { Button, Typography } from 'gazprom-ui-lib';

import LoginWrapper from '../login-wrapper';
import s from './LoginActions.module.scss';

const LoginActions = () => {
  const { signinRedirect } = useAuth();

  const handleSigninRedirect = () => {
    signinRedirect();
  };

  const handleSignUpRedirect = () => {
    userManager.signupRedirect();
  };

  return (
    <LoginWrapper>
      <div className={s.wrapper}>
        <Typography.Title level={3}>{t('access_to_work_with_application_log_in')}</Typography.Title>
        <div className={s.buttons}>
          <Button
            leftIcon="key"
            size="large"
            fullWidth
            type="primary"
            onClick={handleSigninRedirect}>
            {t('access_log_in')}
          </Button>
          <Button
            leftIcon="accountCircle"
            size="large"
            fullWidth
            type="secondary"
            onClick={handleSignUpRedirect}>
            {t('access_sign_up')}
          </Button>
        </div>
      </div>
    </LoginWrapper>
  );
};

export default LoginActions;
