import { DEFAULT_CONFIG_APP } from 'gazprom-ui-lib';

export const theme = {
  ...DEFAULT_CONFIG_APP,
  colorLink: '#006db6',
  colorLinkActive: '#075D96',
  colorLinkHover: '#3ab5f9',
  components: {
    ...DEFAULT_CONFIG_APP?.components,
    Button: {
      ...DEFAULT_CONFIG_APP?.components?.Button,
      colorLink: '#006db6',
      colorLinkActive: '#075D96',
      colorLinkHover: '#3ab5f9',
    },
    Badge: {
      ...DEFAULT_CONFIG_APP?.components?.Badge,
      textFontSizeSM: 9,
      indicatorHeightSM: 12,
      textFontSize: 12,
      indicatorHeight: 16,
    },
  },
};
