import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import useFullSelectedEmployee from './useFullSelectedEmployee.hook';

const useYandexMetrica = () => {
  const location = useLocation();
  const [selectedEmployee] = useFullSelectedEmployee();

  useEffect(() => {
    if ((window as any).ym) {
      (window as any).ym(process.env.98886874, 'hit', location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (selectedEmployee && (window as any).ym) {
      (window as any).ym(process.env.98886874, 'userParams', {
        UserID: selectedEmployee?.person?.id,
      });
    }
  }, [selectedEmployee]);
};

export default useYandexMetrica;
