import dayjs from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

const FALLBACK_LANGUAGE = 'ru';

export const initLanguagesForThirdPartyLibs = () => {
  const INIT_LOCALE = FALLBACK_LANGUAGE;

  import(`dayjs/locale/${INIT_LOCALE}.js`)
    .then(() => {
      dayjs.locale(INIT_LOCALE);
    })
    .catch(() => {
      import(`dayjs/locale/${FALLBACK_LANGUAGE}.js`).then(() => {
        dayjs.locale(FALLBACK_LANGUAGE);
      });
    });
};

export const initDayJsPlugins = () => {
  dayjs.extend(isLeapYear);
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  dayjs.extend(utc);
  dayjs.extend(isToday);
};

export const addYandexMetrica = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.innerHTML = `
      (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();
        for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
        k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");   ym(${process.env.98886874}, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true
      });
    `;
  document.head.appendChild(script);

  const noscript = document.createElement('noscript');
  noscript.innerHTML = `
        <div><img src="https://mc.yandex.ru/watch/${process.env.98886874}" style="position:absolute; left:-9999px;" alt="" /></div>
    `;
  document.body.appendChild(noscript);
};
